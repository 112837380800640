<template>
    <div>
      <div class="header">
        <div class="container">
          <div class="row">
            <div class="col-md-5">
              <div class="logo"><router-link to="/"><img :src="instanceInfo?.logo" :alt="instanceInfo?.name + ' logo'"></router-link></div>
            </div>
          </div><!--/row-->
        </div><!--/container-->
      </div><!--/header-->
        <div class="container page-wrapper">
            <div class="row">
                <div class="col-12">
                    <h1 class="page-title">Checkout</h1>
                </div>
            </div>

            <form id="buy-subscription-form" class="payment-row" @submit.prevent="submitUserAndPayment">
                <div class="review-column">
                    <h3>Order</h3>

                    <div class="product-review">
                        <p>{{ instanceInfo.subscriptionPlan.trialLength }} Day Trial</p>
                        <p>{{ instanceInfo.subscriptionPlan.initialTariff }} {{ instanceInfo.subscriptionPlan.currency }}</p>
                    </div>

                    <div class="product-review totals">
                        <p>
                            <small>Total:</small> <br>
                            {{ instanceInfo.subscriptionPlan.initialTariff }} {{ instanceInfo.subscriptionPlan.currency }}
                        </p>
                        <p>
                            <small>To pay:</small> <br>
                            <span id="currency-display">{{ instanceInfo.subscriptionPlan.initialTariff }} {{ instanceInfo.subscriptionPlan.currency }}</span>
                        </p>
                    </div>

                </div>
                  <div class="pay-column">
                    <h3>Account details<a data-toggle="collapse" data-target=".my-collapse" style="float:right;text-decoration: none;" @click="toggleAccountDetails">
                          <span class="glyphicon" :class="{ 'glyphicon-plus': showingUserDetails == false, 'glyphicon-minus': showingUserDetails == true }"></span>

                      </a></h3>

                      <div class="collapse my-collapse">
                        <div class="row form-group">
                          <label for="gender" class="col-sm-5 control-label">I am a</label>
                          <div class="col-sm-7">
                            <select id="gender" v-model="userGender" class="form-control" name="gender">
                              <option value="MALE">Man</option>
                              <option value="FEMALE">Woman</option>
                            </select>
                          </div>
                        </div>
                        <div class="row form-group">
                          <label for="seek" class="col-sm-5 control-label">Looking to chat with</label>
                          <div class="col-sm-7">
                            <select id="seek" v-model="lookingForGender" class="form-control"
                              name="seek">
                              <option value="FEMALE">Women</option>
                              <option value="MALE">Men</option>
                            </select>
                          </div>
                        </div>

                        <div class="row form-group">
                          <label for="birthdate" class="col-sm-5 control-label">Date of birth</label>
                          <div class="col-sm-7 form-inline">
                            <select v-model="birthDay" class="form-control input-sm" name="birth_day"
                              style="float:left; max-width:60px; margin-right:3px;">
                              <option value="01">1st</option>
                              <option value="02">2nd</option>
                              <option value="03">3rd</option>
                              <option value="04">4th</option>
                              <option value="05">5th</option>
                              <option value="06">6th</option>
                              <option value="07">7th</option>
                              <option value="08">8th</option>
                              <option value="09">9th</option>
                              <option value="10">10th</option>
                              <option value="11">11th</option>
                              <option value="12">12th</option>
                              <option value="13">13th</option>
                              <option value="14">14th</option>
                              <option value="15">15th</option>
                              <option value="16">16th</option>
                              <option value="17">17th</option>
                              <option value="18">18th</option>
                              <option value="19">19th</option>
                              <option value="20">20th</option>
                              <option value="21">21st</option>
                              <option value="22">22nd</option>
                              <option value="23">23rd</option>
                              <option value="24">24th</option>
                              <option value="25">25th</option>
                              <option value="26">26th</option>
                              <option value="27">27th</option>
                              <option value="28">28th</option>
                              <option value="29">29th</option>
                              <option value="30">30th</option>
                              <option value="31">31st</option>
                            </select>

                            <select v-model="birthMonth" class="form-control input-sm"
                              name="birth_month"
                              style="float:left; max-width:65px; margin-right:3px;">
                              <option value="01">Jan</option>
                              <option value="02">Feb</option>
                              <option value="03">March</option>
                              <option value="04">April</option>
                              <option value="05">May</option>
                              <option value="06">June</option>
                              <option value="07">July</option>
                              <option value="08">Aug</option>
                              <option value="09">Sept</option>
                              <option value="10">Oct</option>
                              <option value="11">Nov</option>
                              <option value="12">Dec</option>
                            </select>

                            <select v-model="birthYear" class="form-control input-sm" name="birth_year"
                              style="float:left; max-width:80px;">
                              <option v-for="n in 100"
                                :key="new Date().getFullYear() - n - 17"
                                :value="(new Date().getFullYear() - n - 17).toString()">{{ new Date().getFullYear()
                                  - n - 17 }}
                              </option>
                            </select>
                          </div>
                        </div>

                        <div class="row form-group">
                          <label for="region" class="col-sm-5 control-label">Country</label>
                          <div class="col-sm-7">
                            <select id="locationInput" v-model="country" name="location"
                              class="form-control"
                              required="">
                              <option v-for="country in allowedCountries" :key="country"
                                :value="country">{{ country }}
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>

                      <div v-if="errorMessage" class="alert alert-danger alert-dismissible">
                          <button type="button" class="close" data-dismiss="alert"
                              aria-label="Close" @click="errorMessage = null"><span
                                  aria-hidden="true">×</span></button>
                          <p>{{ errorMessage }}</p>
                      </div>

                      <div class="form-group">
                          <label for="email">Email</label>
                          <div class="input-row">
                              <input id="email" v-model="email" type="email" class="form-control" placeholder="Email"
                                  required="" style="flex: 3;">
                          </div>
                      </div>
                    <h3>Payment details</h3>
                    <div class="form-group">
                        <label for="card-currency">Currency</label>
                        <select id="card-currency" v-model="currentCurrency" class="form-control" name="currency" data-currency-set="" required=""
                            @change="$emit('update:currency', $event.target.value)">
                                <option v-for="sp in instanceInfo?.subscriptionPlans" :key="sp.currency" :value="sp.currency">{{ sp.currency }}</option>
                                </select>
                    </div>
                    <div class="form-group">
                        <label for="card-number">Card number</label>
                        <div class="input-row">
                            <input id="card-number" v-model="cardNumber" type="text" class="form-control" placeholder="Number" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                name="card[number]" data-accept="digits" maxlength="16" required="" style="flex: 3;">

                            <input id="card-ccv" v-model="cardCode" type="tel" class="form-control" placeholder="CVV" name="card[code]" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                data-accept="digits" required="" maxlength="3" style="flex: 1;">
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="card-year">Expires on</label>
                        <div class="input-row">
                          <select
                              id="card-month"
                              v-model="cardExpiryMonth"
                              required=""
                              class="form-control"
                          >
                            <option value="" hidden>Month</option>
                            <option
                                v-for="n in 12"
                                :key="n.toString().padStart(2, '0')"
                                :value="n.toString().padStart(2, '0')"
                            >
                              {{ n.toString().padStart(2, '0') }}
                            </option>
                          </select>

                          <select
                              id="card-year"
                              v-model="cardExpiryYear"
                              required=""
                              class="form-control"
                          >
                            <option value="" hidden>Year</option>
                            <option
                                v-for="n in 10"
                                :key="new Date().getFullYear() + n - 1"
                                :value="(new Date().getFullYear() + n - 1).toString()"
                            >
                              {{ new Date().getFullYear() + n - 1 }}
                            </option>
                          </select>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="card-name">Name on card</label>
                        <input  id="card-name" v-model="cardHolder" type="text" name="card[holder]" class="form-control" required=""
                            placeholder="Name">
                    </div>
                </div>
                <div class="footer-column">
                    <div class="form-group">
                        <div class="checkbox">
                            <label>
                                <input type="checkbox" name="checks[privacy]" required=""> I have read and accept the
                                <router-link to="/privacy">Privacy Policy</router-link> and <router-link to="/terms">Terms
                                    &amp; Conditions</router-link>.
                            </label>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="checkbox">
                            <label>
                                <input type="checkbox" name="checks[subscription]" required="">
                                By clicking 'Complete Payment' you agree that you are engaging in a {{ instanceInfo.subscriptionPlan.trialLength }} Days Trial Membership
                                (starting today). Your credit card will be automatically billed every {{ instanceInfo.subscriptionPlan.daysInterval }} days from the end
                                date of your trial period,
                                If you don't cancel your trial subscription within the first {{ instanceInfo.subscriptionPlan.trialLength }} days your membership will be
                                automatically renewed for {{ instanceInfo.subscriptionPlan.recurringTariff }} {{ instanceInfo.subscriptionPlan.currency }} every {{ instanceInfo.subscriptionPlan.daysInterval }} days.
                                To cancel subscription you can login in the <a :href="'https://' + instanceInfo?.domain + '/login'" target="_blank">Portal</a>
                                and then go to <a :href="'https://' + instanceInfo?.domain + '/settings'" target="_blank">Settings</a> and click "Cancel my subscription".
                                Alternatively you can use <a :href="'https://' + instanceInfo?.domain + '/contact'" target="_blank">Contact Form</a>, provide your data
                                (used when subscribing) and submit message to our Customer Service or send email to <a :href="'mailto:'+ instanceInfo?.supportEmail">{{ instanceInfo.supportEmail }}</a>
                            </label>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="checkbox">
                            <label>
                                <input type="checkbox" name="checks[charges]" required="">
                                Charges made to your credit card will appear under "{{ instanceInfo.supportDomain }} {{
                                    instanceInfo.phoneNumber }}", operated by <span class="rtl">{{ companyName }}</span>, an eCommerce
                                Merchant located {{ instanceInfo.companyAddress1 }}, {{ instanceInfo.companyAddress2 }}.
                            </label>
                        </div>
                    </div>
                    <button type="submit" class="btn btn-default">Complete Payment</button>
                    <hr>
                    <div class="payment-logos">
                        <img src="/theme/001/images/payment/payment_logos.png" alt="Payment method logo">
                    </div>
                </div>
            </form>


        </div>
        <Footer :instance-info="instanceInfo" :company-name="companyName" v-bind="$attrs" />
    </div>
<link href="/theme/001/css/checkout.css" rel="stylesheet">
</template>

<script>
import { ref, toRef, watch } from 'vue';
import Footer from '@/app/components/Footer';
import { makePayment } from '@/app/api/user';
import {createAndSendForm, createIFrameAndResolveMessage, handlePrecondition} from '@/app/use/payment';
import { redirectToRoute } from '@/app/router';
import { getCountry } from '@/app/helpers/country';
import moment from 'moment';
import {loadScript} from "vue-plugin-load-script";

export default {
    name: 'SubscriptionDirectPremium',
    components: { Footer },
    props: {
        instanceInfo: {
            type: Object,
            required: false,
        },
        companyName: {
          type: String,
          required: false,
        },
    },
    setup(props) {
      const allowedCountries = ['Algeria', 'Andorra', 'Angola', 'Argentina', 'Armenia', 'Australia', 'Austria', 'Azerbaijan', 'Bahamas', 'Bahrain', 'Bangladesh', 'Barbados', 'Belarus', 'Belgium', 'Bolivia', 'Bosnia & Herzegovina', 'Botswana', 'Brazil', 'Bulgaria', 'Cambodia', 'Cameroon', 'Canada', 'Central African Rep.', 'Chad', 'Chile', 'China', 'Colombia', 'Congo (Dem. Rep.)', 'Congo (Rep.)', 'Costa Rica', 'Croatia', 'Cuba', 'Cyprus', 'Czech Republic', 'Denmark', 'Dominica', 'Dominican Republic', 'Ecuador', 'Egypt', 'Eritrea', 'Estonia', 'Ethiopia', 'Fiji', 'Finland', 'France', 'French Guiana', 'French Polynesia', 'Gabon', 'Gambia', 'Georgia', 'Germany', 'Ghana', 'Gibraltar', 'Greece', 'Greenland', 'Guatemala', 'Guinea', 'Guyana', 'Haiti', 'Honduras', 'Hong Kong', 'Hungary', 'Iceland', 'India', 'Indonesia', 'Iran', 'Iraq', 'Ireland', 'Israel', 'Italy', 'Jamaica', 'Japan', 'Jersey', 'Jordan', 'Kazakhstan', 'Kenya', 'Korea (North)', 'Korea (South)', 'Kuwait', 'Kyrgyzstan', 'Latvia', 'Lebanon', 'Liberia', 'Libya', 'Liechtenstein', 'Lithuania', 'Luxembourg', 'Madagascar', 'Malaysia', 'Maldives', 'Mali', 'Malta', 'Mexico', 'Micronesia', 'Moldova', 'Monaco', 'Mongolia', 'Morocco', 'Mozambique', 'Myanmar (Burma)', 'Netherlands', 'New Zealand', 'Nicaragua', 'Niger', 'Nigeria', 'North Macedonia', 'Norway', 'Oman', 'Pakistan', 'Panama', 'Paraguay', 'Peru', 'Philippines', 'Poland', 'Portugal', 'Puerto Rico', 'Qatar', 'Romania', 'Russia', 'San Marino', 'Saudi Arabia', 'Senegal', 'Serbia', 'Singapore', 'Slovakia', 'Slovenia', 'South Africa', 'Spain', 'Sudan', 'Sweden', 'Switzerland', 'Taiwan', 'Thailand', 'Tunisia', 'Turkey', 'Ukraine', 'United Arab Emirates', 'United Kingdom of Great Britain and Northern Ireland', 'United States of America', 'Uruguay', 'Venezuela', 'Vietnam'];


    const subscriptionPlan = toRef(props.instanceInfo, 'subscriptionPlan');
    const currentCurrency = ref(subscriptionPlan.value.currency);

    watch(
      () => props.instanceInfo,
      async () => {
        currentCurrency.value = props.instanceInfo.subscriptionPlan.currency;
      },
      { immediate: true, deep: true }
    );

    const errorMessage = ref('');
    const lookingForGender = ref('FEMALE');
    const userGender = ref('MALE');
    const birthDay = ref('01');
    const birthMonth = ref('01');
    const birthYear = ref('1980');
    const email = ref(null);
    const country = ref(getCountry());
    const showingUserDetails = ref(false);

    const cardNumber = ref(null);
    const cardHolder = ref(null);
    const cardCode = ref(null);
    const cardExpiryMonth = ref((new Date().getMonth()+1));
    const cardExpiryYear = ref(new Date().getFullYear());

    const isPaying = ref(false);

    const submitUserAndPayment = () => {
        const birthDateString = birthYear.value + '-' + birthMonth.value + '-' + birthDay.value;
        if (!moment(birthDateString, "YYYY-MM-DD", true).isValid() || moment().diff(birthDateString, 'years', false) < 18) {
            errorMessage.value = "Invalid birthdate";
            return;
        }
        isPaying.value = true;
        const payRequest = {
          user: {
            gender: userGender.value,
            lookingForGender: lookingForGender.value,
            birthDate: birthDateString,
            country: country.value,
            email: email.value,
            username: email.value.split('@')[0],
          },
          currency: currentCurrency.value,
          card: {
            holder: cardHolder.value,
            number: cardNumber.value,
            code: cardCode.value,
            expiry: cardExpiryMonth.value.toString().padStart(2, '0') + cardExpiryYear.value,
          },
          browser: {
            language: navigator.language,
            screenHeight: screen.height,
            screenWidth: screen.width,
            screenColorDepth: screen.colorDepth,
            timezone: new Date().getTimezoneOffset(),
            userAgent: navigator.userAgent,
            javaEnabled: false,
            javaScriptEnabled: true,
          }
        };
        makePayment(payRequest)
          .then(response => {
            handleResponse(response);
          })
          .catch(() => {
            redirectToRoute('/credits/subscription/direct-premium/failure');
          })
          .finally(() => (isPaying.value = false));
    }

    const submitPaymentWith3DSData = (transactionId, threeDS) => {
        const birthDateString = birthYear.value + '-' + birthMonth.value + '-' + birthDay.value;
        isPaying.value = true;
        const payRequest = {
          user: {
            gender: userGender.value,
            lookingForGender: lookingForGender.value,
            birthDate: birthDateString,
            country: country.value,
            email: email.value,
            username: email.value.split('@')[0],
          },
          currency: currentCurrency.value,
          card: {
            holder: cardHolder.value,
            number: cardNumber.value,
            code: cardCode.value,
            expiry: cardExpiryMonth.value.toString().padStart(2, '0') + cardExpiryYear.value,
          },
          browser: {
            language: navigator.language,
            screenHeight: screen.height,
            screenWidth: screen.width,
            screenColorDepth: screen.colorDepth,
            timezone: new Date().getTimezoneOffset(),
            userAgent: navigator.userAgent,
            javaEnabled: false,
            javaScriptEnabled: true,
          },
          threeDS: {
            transactionId: transactionId,
            data: btoa(JSON.stringify(threeDS))
          }
        };
        makePayment(payRequest)
          .then(response => {
            handleResponse(response);
          })
          .catch(() => {
            redirectToRoute('/credits/subscription/direct-premium/failure');
          })
          .finally(() => (isPaying.value = false));
    }

    const handlePendingResponse = (response) => {
      const threeDSResponse = response.threeDSResponse;

      if (response.gateway === 'NET_VALVE') {
          const transactionId = threeDSResponse.transactionId;
          const url = threeDSResponse.url;
          const isHidden = threeDSResponse.hidden;

          createIFrameAndResolveMessage(url, isHidden).then(res => {
              submitPaymentWith3DSData(transactionId, res);
          });

          return;
      }

      if (response.gateway === 'BILL_1ST') {
        const merchantCode = threeDSResponse.merchantCode;
        const jwt = threeDSResponse.jwt;
        const transactionId = threeDSResponse.transactionId;
        const amount = threeDSResponse.amount;
        const currencyNumericCode = threeDSResponse.currencyNumericCode;
        const force = threeDSResponse.force;

        loadScript(
            'https://secure3d.bill1st.com/js/v2/Bill1stSecure3D.js?profile='
        )
            .then(() => {
              // eslint-disable-next-line no-undef
              const Secure = new Secure3D();
              Secure.setup(merchantCode, jwt);
              Secure.enableBinDetection('card-number');
              Secure.on('payments.validated', function (data) {
                submitPaymentWith3DSData(transactionId, data);
              });

              Secure.on('payments.noAction', function (data) {
                submitPaymentWith3DSData(transactionId, data);
              });

              Secure.on('payments.setupComplete', function () {
                try {
                  const order = {
                    Consumer: {
                      Email1: email.value,
                      BillingAddress: {
                        FullName: cardHolder.value
                      },
                      Account: {
                        AccountNumber: cardNumber.value,
                        ExpirationMonth: cardExpiryMonth.value.toString().padStart(2, '0'),
                        ExpirationYear: cardExpiryYear.value,
                        CardCode: cardCode.value,
                        NameOnAccount: cardHolder.value
                      }
                    },
                    OrderDetails: {
                      Amount: amount,
                      CurrencyCode: currencyNumericCode
                    }
                  };
                  console.log(order);
                  if (force) {
                    Secure.forceAuthentication(order);
                  } else {
                    Secure.do3D(order);
                  }
                } catch (error) {
                  redirectToRoute('/credits/subscription/direct-premium/failure');
                }
              });

              Secure.on('payments.errorSetup', function () {
                redirectToRoute('/credits/subscription/direct-premium/failure');
              });
            });
      } else {
        const threeDUrl = threeDSResponse.url;
        const preconditions = threeDSResponse.preconditions;
        const threeDUrlParams = threeDSResponse.params;
        if (threeDUrl != null && preconditions && preconditions.length) {
          Promise.all(
            preconditions.map((precondition, index) =>
              handlePrecondition({
                index,
                ...precondition
              }),
            ),
          )
            .then(() => {
              createAndSendForm(threeDUrl, threeDUrlParams);
            })
            .catch(() => {
              redirectToRoute('/credits/subscription/direct-premium/failure');
            });
        } else if (threeDUrl != null) {
          createAndSendForm(threeDUrl, threeDUrlParams);
        } else {
          redirectToRoute('/credits/subscription/direct-premium/failure');
        }
      }
    };

    const handleResponse = (response) => {
      if (response.status === 'SUCCESSFUL') {
        redirectToRoute('/credits/subscription/direct-premium/success');
      } else if (response.status === 'PENDING') {
        handlePendingResponse(response);
      } else {
        redirectToRoute('/credits/subscription/direct-premium/failure');
      }
    };

    const toggleAccountDetails = () => {
      showingUserDetails.value = !showingUserDetails.value;
    };

    return {
      currentCurrency, userGender, lookingForGender, birthDay, birthMonth, birthYear, email, country, errorMessage,
      showingUserDetails, allowedCountries, cardNumber, cardHolder, cardCode, cardExpiryMonth, cardExpiryYear, submitUserAndPayment, toggleAccountDetails
    };
  },
};
</script>
